import { template as template_79dfcc17a08249598ad5884c600c87c5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_79dfcc17a08249598ad5884c600c87c5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
