import { template as template_6102eba35d34462b9a65b7ac9d48257d } from "@ember/template-compiler";
const SidebarSectionMessage = template_6102eba35d34462b9a65b7ac9d48257d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
