import { template as template_5e3dfc8525aa4cef82deedf25ed0f9ea } from "@ember/template-compiler";
const WelcomeHeader = template_5e3dfc8525aa4cef82deedf25ed0f9ea(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
